import React from 'react';
import PropTypes from 'prop-types';
import LinkText from '../../../common/components/link-text';
import { connect } from '../../../common/components/runtime-context';
import { isEditor } from '../../../common/store/basic-params/basic-params-selectors';
import LinkList from '../../../link-list';
import { getArchiveWidgetData } from '../../aggregated-archive/selectors';
import {
  getMonthsDisplayLimit,
  getShowNewestPostsFirst,
  getShowPostCount,
  getUseMobileDesingSettings,
  getUseMobileLayoutSettings,
} from '../../selectors/archive-selectors';

class Archive extends React.Component {
  componentDidUpdate(prevProps) {
    const editor = this.props.isEditor;
    const showNewestPostsFirstChanged =
      prevProps.showNewestPostsFirst !== this.props.showNewestPostsFirst;
    const monthsDisplayLimitChanged =
      prevProps.monthsDisplayLimit !== this.props.monthsDisplayLimit;

    if (editor && (showNewestPostsFirstChanged || monthsDisplayLimitChanged)) {
      this.props.fetchArchiveWidgetInitialData();
    }
  }

  render() {
    const {
      archiveData,
      showPostCount,
      useMobileDesignSettings,
      useMobileLayoutSettings,
      monthsDisplayLimit,
    } = this.props;
    return (
      <nav aria-label={archiveData.ariaLabel}>
        <LinkList
          links={archiveData.archive
            .slice(0, monthsDisplayLimit)
            .map((archiveLink) => {
              const monthAndYear = archiveLink.display.text;
              const a11yText = archiveLink.display.a11yText;
              const postCount = showPostCount
                ? archiveLink.display.postCount
                : null;

              return {
                key: archiveLink.key,
                path: archiveLink.path,
                text: (
                  <LinkText postCount={postCount} a11yText={a11yText}>
                    {monthAndYear}
                  </LinkText>
                ),
              };
            })}
          emptyState={{ text: archiveData.emptyState }}
          useMobileDesignSettings={useMobileDesignSettings}
          useMobileLayoutSettings={useMobileLayoutSettings}
        />
      </nav>
    );
  }
}

Archive.propTypes = {
  archiveData: PropTypes.object.isRequired,
  showPostCount: PropTypes.bool.isRequired,
  useMobileDesignSettings: PropTypes.bool.isRequired,
  useMobileLayoutSettings: PropTypes.bool.isRequired,
  showNewestPostsFirst: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool.isRequired,
  fetchArchiveWidgetInitialData: PropTypes.func.isRequired,
  monthsDisplayLimit: PropTypes.number.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  archiveData: getArchiveWidgetData(state),
  monthsDisplayLimit: getMonthsDisplayLimit(state),
  showPostCount: getShowPostCount(state),
  useMobileDesignSettings: getUseMobileDesingSettings(state),
  useMobileLayoutSettings: getUseMobileLayoutSettings(state),
  showNewestPostsFirst: getShowNewestPostsFirst(state),
  isEditor: isEditor(state),
  fetchArchiveWidgetInitialData: actions.fetchArchiveWidgetInitialData,
});

export default connect(mapRuntimeToProps)(Archive);
